<template>
  <div class="top">
    <ul class="top-slides-bg-circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <ul class="top-slides-bg-circles2">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <v-dialog v-model="dialog" transition="fade-transition" width="80%" max-width="1280" max-height="80%">
      <v-card>
        <v-list>
          <v-list-item>
            <v-row>
              <v-col cols="12" lg="6" xl="6" xxl="6">
                <v-card-text class="discography-list-src">
                  <v-img :src="cd.src"></v-img>
                </v-card-text>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                xl="6"
                xxl="6"
                class="d-flex flex-column text-center"
              >
                <v-card-title
                  class="discography-list-title text-h5 py-4"
                  style="color: #222"
                  >{{ cd.title }}</v-card-title
                >
                <div class="discography-list-booth mt-6">
                  <p v-if="cd.url == ''">
                    <a ></a>
                  </p>
                  <p v-else>
                    <a :href="cd.url" target="_blank"
                    ><BoothLogo /><v-text>BOOTHで購入</v-text></a
                  >
                  </p>                  
                </div>
                <v-card-text
                  class="discography-list-text"
                  style="color: #222"
                  ><span v-html="cd.text"></span
                ></v-card-text>
                <div class="discography-music-list">
                  <p v-for="(item, i) in cd.list" :key="i">
                    track{{ item.track }}: {{ item.name }}
                  </p>
                </div>
                <v-card-text
                  class="text-right text-caption text-bottom"
                  style="color: #777"
                  >{{ cd.date }}</v-card-text
                >
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="mt">
            <v-col cols="12"><p class="topic">Discography</p></v-col>
          </v-row>
          <div class="py-4"></div>
          <v-row>
            <v-col
              cols="6"
              sm="4"
              md="3"
              lg="3"
              xl="3"
              xxl="3"
              v-for="(item, i) in cds"
              :key="i"
              class="discography-list"
            >
              <div class="ma-3">
                <v-card height="100%" class="links" @click="dialogOn(item)">
                  <v-img v-bind:src="item.src"></v-img>
                  <v-card-title
                    class="text-left text-subtitle-2 mt-3 pa-0 pl-3"
                    :style="{ color: item.color }"
                    >{{ item.genre }}</v-card-title
                  >
                  <v-card-title
                    class="discography-list-title text-body-1 pa-0"
                    style="color: #222"
                    >{{ item.title }}</v-card-title
                  >
                  <v-card-text
                    class="text-right text-caption text-bottom"
                    style="color: #777"
                    >{{ item.date }}</v-card-text
                  >
                  <v-card-text>{{ "" }}</v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="py-12"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
import BoothLogo from "../assets/Booth_logo.svg";
export default {
  data: () => ({
    dialog: false,
    cd: {
      url: "",
      src: "",
      title: "",
      text: "",
      date: "",
      list: [{ track: 0, name: "" }],
    },
    cds: [
    {
        url: "",
        src: require("../assets/discography/CD9.webp"),
        title: "Melodious Melodies 1",
        text: '念願の<a>初のボカロアルバム</a>です',
        date: "2024秋M3",
        list: [
          { track: 1, name: "すりーまいん" },
          { track: 2, name: "マスカレード" },
          { track: 3, name: "だいぼうそう" },
          { track: 4, name: "椅子占り遊戯" },
          { track: 5, name: "デイアイシィ" },
          { track: 6, name: "終わりの日に" },
          { track: 7, name: "すりーまいん(inst.)" },
          { track: 8, name: "マスカレード(inst.)" },
          { track: 9, name: "だいぼうそう(inst.)" },
          { track: 10, name: "椅子占り遊戯(inst.)" },
          { track: 11, name: "デイアイシィ(inst.)" },
          { track: 12, name: "終わりの日に(inst.)" },
        ],
      },

    {
        url: "https://semicolon0103.booth.pm/items/5692925",
        src: require("../assets/discography/CD8.webp"),
        title: "Fortepiano1",
        text: '念願の<a>初のピアノ純度100%アルバム</a>です',
        date: "2024春M3",
        list: [
          { track: 1, name: "海" },
          { track: 2, name: "Eternity Memoria" },
          { track: 3, name: "stadion (原曲: 吟世かいな 編曲: 相生あおは)" },
          { track: 4, name: "雨空とビニール傘 ​(原曲: Mizzle 編曲: 相生あおは)" },
          { track: 5, name: "メルナ様にはかなわない (原曲: nearain 編曲: 相生あおは)" },
          { track: 6, name: "トリルのための練習曲 1.逡巡" },
          { track: 7, name: "トリルのための練習曲 2.夢夜" },
          { track: 8, name: "トリルのための練習曲 3.思惟" },
        ],
      },


    {
        url: "https://semicolon0103.booth.pm/items/5315814",
        src: require("../assets/discography/CD7.webp"),
        title: "Graduate Nekochan",
        text: '<a>青葉台第一高校DTM部♪</a>としての<a>初のアルバム</a>です',
        date: "2023秋M3",
        list: [
          { track: 1, name: "Asteroid Nekochan" },
          { track: 2, name: "Daydream" },
          { track: 3, name: "stadion (piano ver.)" },
          { track: 4, name: "テレカクシ・テレパシー" },
          { track: 5, name: "テレカクシ・テレパシー (off vocal)" },
          { track: 6, name: "夕焼けのまほう" },
          { track: 7, name: "夕焼けのまほう (off vocal)" },
          { track: 8, name: "Voyager Nekochan" },
          { track: 9, name: "Graduate Nekochan" },
        ],
      },
      {
        url: "https://semicolon0103.booth.pm/items/4802585",
        src: require("../assets/discography/CD6.webp"),
        title: "とんえぼ音楽隊",
        text: "東北大学音ゲーサークル<a>とんえぼの作曲メンバーたち</a>による初の<a>コンピレーションCD</a>です。",
        date: "2023春M3",
        list: [
          { track: 1, name: "Blue | K" },
          { track: 2, name: "Swin! | Min" },
          { track: 3, name: "ex-Mate | 龍崎カラム" },
          { track: 4, name: "Fragmentation | Tajima Haruo" },
          { track: 5, name: "少女セドナとオールトの迷宮 | 吟世かいな" },
          { track: 6, name: "勈徃邁進 | 相生あおは" },
          { track: 7, name: "角宿ノ守人 | lufm/らふむ" },
          { track: 8, name: "境界の民 | RyuElvia" },
        ],
      },
      {
        url: "https://semicolon0103.booth.pm/items/4802579",
        src: require("../assets/discography/CD5.webp"),
        title: "Melodious Records 5",
        text: "<a>同人音ゲー曲</a>と、<a>みえない二等星</a>の楽曲、および<a>書き下ろしピアノ練習曲</a>(?)を収録しました。",
        date: "2022秋M3",
        list: [
          { track: 1, name: "Frontier Nekochan" },
          { track: 2, name: "Glittem" },
          { track: 3, name: "Knitefight" },
          { track: 4, name: "Nightlude" },
          { track: 5, name: "Lemonadegrenade" },
          { track: 6, name: "みえない二等星_OPver." },
          { track: 7, name: "みえない二等星" },
          { track: 8, name: "軽音部集合！" },
          { track: 9, name: "標の星" },
          { track: 10, name: "美波のテーマ" },
          { track: 11, name: "高村のテーマ" },
          { track: 12, name: "トリルのための練習曲" },
        ],
      },
      {
        url: "https://semicolon0103.booth.pm/items/4802573",
        src: require("../assets/discography/CD4.webp"),
        title: "Melodious Records 4",
        text: "<a>同人音ゲー曲</a>と、<a>雨にして人を外れ</a>の楽曲を収録しました。",
        date: "2022春M3",
        list: [
          { track: 1, name: "Asteroid Nekochan" },
          { track: 2, name: "Bayside Nekochan" },
          { track: 3, name: "Cuddle Nekochan" },
          { track: 4, name: "Fierceness" },
          { track: 5, name: "Affectation" },
          { track: 6, name: "雨にして人を外れ_OPver." },
          { track: 7, name: "雨にして人を外れ" },
          { track: 8, name: "日常会話" },
          { track: 9, name: "あじさい園" },
          { track: 10, name: "僕の先輩" },
          { track: 11, name: "外の光" },
          { track: 12, name: "雨妖" },
          { track: 13, name: "心の中へ" },
        ],
      },
      {
        url: "https://semicolon0103.booth.pm/items/4802571",
        src: require("../assets/discography/CD3.webp"),
        title: "Melodious Records 3",
        text: "<a>同人音ゲー曲</a>と、<a>ひとつ屋根の下なのに</a>の楽曲を収録しました。",
        date: "2022春M3",
        list: [
          { track: 1, name: "Operon" },
          { track: 2, name: "Highspeed Nekochan" },
          { track: 3, name: "Maiden Nekochan" },
          { track: 4, name: "Melancholy" },
          { track: 5, name: "Contemplation" },
          { track: 6, name: "OP" },
          { track: 7, name: "ひとつ屋根の下なのに" },
          { track: 8, name: "あのね？" },
          { track: 9, name: "なに買お？" },
          { track: 10, name: "ほっ…" },
          { track: 11, name: "そんな！" },
          { track: 12, name: "もやもや" },
          { track: 13, name: "ED" },
        ],
      },
      {
        url: "https://semicolon0103.booth.pm/items/4802560",
        src: require("../assets/discography/CD2.webp"),
        title: "Melodious Records 2",
        text: "<a>初のCDその2</a>。<a>同人音ゲー曲</a>と、<a>IdealMemoria</a>で担当した楽曲を収録しました。",
        date: "2021秋M3",
        list: [
          { track: 1, name: "Bravelgent" },
          { track: 2, name: "夢色の花束" },
          { track: 3, name: "エレクトリカル・トキシック" },
          { track: 4, name: "スズメノセカイ" },
          { track: 5, name: "Popping Nekochan" },
          { track: 6, name: "渓谷" },
          { track: 7, name: "樹海" },
          { track: 8, name: "神樹" },
          { track: 9, name: "悲劇" },
          { track: 10, name: "洞窟" },
          { track: 11, name: "荒野" },
          { track: 12, name: "決闘" },
        ],
      },
      {
        url: "https://semicolon0103.booth.pm/items/4802533",
        src: require("../assets/discography/CD1.webp"),
        title: "Melodious Records 1",
        text: "<a>初のCD</a>。<a>同人音ゲー曲</a>と、<a>海洋生物たちの復讐2</a>で担当した楽曲(&没曲)を収録しました。",
        date: "2021秋M3",
        list: [
          { track: 1, name: "Shiftenery" },
          { track: 2, name: "Skyhigh Nekochan" },
          { track: 3, name: "Daydream" },
          { track: 4, name: "Vermitation" },
          { track: 5, name: "Voyager Nekochan" },
          { track: 6, name: "stage2" },
          { track: 7, name: "boss2" },
          { track: 8, name: "stage5" },
          { track: 9, name: "boss5" },
          { track: 10, name: "stage7" },
          { track: 11, name: "boss7-第一形態" },
          { track: 12, name: "boss7-第二形態" },
          { track: 13, name: "boss7-裏第二形態" },
          { track: 14, name: "エンディング" },
          { track: 15, name: "裏エンディング" },
        ],
      },
    ],
  }),

  methods: {
    dialogOn(content) {
      this.cd = content;
      this.dialog = true;
    },
  },
  components: {
    BoothLogo,
  },
};
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
