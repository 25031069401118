<template>
  <div class="top">
    <ul class="top-slides-bg-circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <ul class="top-slides-bg-circles2">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="mt">
            <v-col cols="12"><p class="topic">Works</p></v-col>
            <v-col cols="1" sm="6" md="8" lg="8" xl="8" xxl="8" class="pb-0"></v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4" xxl="4" class="pb-0">
              <v-select v-model="selectGenre" :items="genres" class="pulldown"></v-select>
            </v-col>
            <v-col cols="12" v-for="(item, i) in items" :key="i" class="ma-0">
              <v-card v-if="item.divider === ''" color="rgba(250,250,250,0.5)" :href="item.url" class="works-list" target="_blank">
                <div class="d-md-flex junstify-center align-center">
                  <div class="works-list-item">
                    <div class="works-list-item2">
                      <div class="works-list-date">{{ item.date }}</div>
                      <div class="works-list-genre">
                        <v-chip :color=item.color class="works-list-genre-chip">{{ item.genre }}</v-chip>
                      </div>
                    </div>
                    <div class="works-list-title">{{ item.title }}</div>
                  </div>
                  <div class="works-list-text">{{ item.text }}</div>
                </div>
              </v-card>
              <div v-else>
                <p class="works-list-divider">{{ item.divider }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="py-12"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectGenre: "All Genre",
    genres: [ "All Genre", "Melodious Nekochan", "D10RAMA", "Article", "TonEvo", "Micomprocedure", "Music Game", "Other Games", "Others" ],
    musics: [
    {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2024",
      },
      {
        url: "https://semicolon0103.bandcamp.com/",
        date: "24/11/06",
        genre: "Melodious Nekochan",
        color: "red",
        title: "Bandcamp",
        text: "Bandcampを開設しました",
        divider: "",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1850346840568520750",
        date: "24/10/27",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2024秋",
        text: "個人サークル Melodious Nekochan として頒布をしました",
        divider: "",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2021_12_1",
        date: "24/08/25",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "旅行記",
        text: "修士二年の12月に北海道を旅した際の話を書きました",
        divider: "",
      },      
      {
        url: "https://twitter.com/D10RAMA_VN/status/1822797474475688421",
        date: "24/08/12",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "コミックマーケット C104",
        text: "D10RAMAとして頒布を行いました",
        divider: "",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1784391478007288066",
        date: "24/04/28",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2024春",
        text: "個人サークル Melodious Nekochan として頒布をしました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2023",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1713132544047149493",
        date: "23/10/29",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2023秋",
        text: "青葉台第一高校DTM部♪ として頒布をしました",
        divider: "",
      },
      {
        url: "https://twitter.com/D10RAMA_VN/status/1684476514426060801",
        date: "23/07/27",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "『雨にして人を外れ』",
        text: "英語版をリリースしました",
        divider: "",
      },
      {
        url: "https://twitter.com/D10RAMA_VN/status/1675492133355282432",
        date: "23/07/02",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "『雨にして人を外れ』",
        text: "steamで1万ダウンロードを達成しました",
        divider: "",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1647540335491309575",
        date: "23/04/30",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2023春",
        text: "個人サークル Melodious Nekochan として頒布をしました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2022",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/16/140000",
        date: "22/12/16",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "オモゐデ漁船 Advent Calendar 2022",
        text: "16日目担当として旅行記を公開しました",
        divider: "",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/09/140000",
        date: "22/12/09",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "とんえぼ老人部 Advent Calendar 2022",
        text: "9日目担当としてSurface改造機+αを公開しました",
        divider: "",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/08/140000",
        date: "22/12/08",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "とんえぼ老人部 Advent Calendar 2022",
        text: "8日目担当としてWordファイル修復記を公開しました",
        divider: "",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/04/140000",
        date: "22/12/04",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "とんえぼ老人部 Advent Calendar 2022",
        text: "4日目担当として旅行記を公開しました",
        divider: "",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2022/12/01/000000",
        date: "22/12/01",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "とんえぼ老人部 Advent Calendar 2022",
        text: "1日目担当として創作班紹介記事を公開しました",
        divider: "",
      },
      {
        url: "https://twitter.com/D10RAMA_VN/status/1589106331797315584",
        date: "22/11/6",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "デジゲー博",
        text: "D10RAMAとして頒布を行いました",
        divider: "",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1581208258584289281",
        date: "22/10/30",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2022秋",
        text: "個人サークル Melodious Nekochan として頒布をしました",
        divider: "",
      },
      {
        url: "https://manbow.nothing.sh/event/event.cgi?action=More_def&num=429&event=140",
        date: "22/10/23",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "THE BMS OF FIGHTERS XVIII -Summer Dream Traveler-",
        text: "旅路への憧憬(BMS edit.)を公開しました",
        divider: "",
      },
      {
        url: "https://www.gamespark.jp/article/2022/10/01/123030.html",
        date: "22/10/01",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "Game*Spark",
        text: "『雨にして人を外れ』がメディア掲載されました",
        divider: "",
      },
      {
        url: "https://twitter.com/D10RAMA_VN/status/1558243458019905536",
        date: "22/08/13",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "コミックマーケット C100",
        text: "D10RAMAとして頒布を行いました",
        divider: "",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol11",
        date: "22/07/18",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.11 -new era, new nova-",
        text: "Graduate Nekochanが収録されました",
        divider: "",
      },
      {
        url: "https://d10rama.com/nitousei/",
        date: "22/06/11",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "『みえない二等星』",
        text: "一部BGMを担当しました",
        divider: "",
      },
      {
        url: "https://twitter.com/xima_334/status/1525100626719277056",
        date: "22/05/13",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "チキチキ〆切パッケージ2022初春",
        text: "勈徃邁進が収録されました",
        divider: "",
      },
      {
        url: "https://omoidegyosen.bandcamp.com/album/omoide-world-tour",
        date: "22/05/01",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "オモゐデ漁船 新譜デジタルリリース",
        text: "セピア色の記憶が収録されました",
        divider: "",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1515646252200865792",
        date: "22/04/24",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2022春",
        text: "個人サークル Melodious Nekochan として頒布をしました",
        divider: "",
      },
      {
        url: "https://characterpackage.wixsite.com/risingtriad",
        date: "22/04/09",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "Rising Triad",
        text: "スズメノセカイが収録されました",
        divider: "",
      },
      {
        url: "https://news.mynavi.jp/article/20220127-2258332/",
        date: "22/01/27",
        genre: "Others",
        color: "",
        title: "バズツイ",
        text: "バズったツイートが記事になってしまいました",
        divider: "",
      },
      {
        url: "https://shiheikshoot.web.fc2.com/KSM_SLOW/",
        date: "22/01/07",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "Serene Life On Waterfront",
        text: "Nightludeが収録されました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2021",
      },
      {
        url: "https://manbow.nothing.sh/event/event.cgi?action=More_def&num=200&event=137",
        date: "21/12/26",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "THE BMS OF FIGHTERS XVII -VISION THE RETRO FUTURE-",
        text: "Popping Nekochan(BMS edit.)とBravelgent(BMS edit.)を公開しました",
        divider: "",
      },
      {
        url: "https://semicolon0103.hatenablog.com/entry/2021/12/16/180000_1",
        date: "21/12/16",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "オモゐデ漁船 Advent Calendar 2021",
        text: "16日目担当として旅行記を公開しました",
        divider: "",
      },
      {
        url: "https://tonevoadventcalendar.hatenablog.com/entry/2021/12/08/170000?_ga=2.84277273.1452954375.1638761262-1652904612.1635934598",
        date: "21/12/08",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "Tonevo Advent Calendar 2021",
        text: "8日目担当としてM3参加記+αを公開しました",
        divider: "",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol10",
        date: "21/12/03",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.10 -Evolved 2021-",
        text: "Frontier Nekochan, Lemonadegrenade, shot glassが収録されました",
        divider: "",
      },
      {
        url: "https://twitter.com/D10RAMA_VN/status/1458819421191884805",
        date: "21/11/12",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "デジゲー博",
        text: "D10RAMAとして頒布を行いました",
        divider: "",
      },
      {
        url: "https://twitter.com/djxxxholic/status/1456196139049361414",
        date: "21/11/01",
        genre: "Others",
        color: "",
        title: "PLANET3 GALAXY",
        text: "Nightludeが収録されました",
        divider: "",
      },
      {
        url: "https://muri-nishimori.booth.pm/items/3365407",
        date: "21/10/31",
        genre: "Others",
        color: "",
        title: "超DTM部 部内大会",
        text: "Glittemが収録されました",
        divider: "",
      },
      {
        url: "https://twitter.com/semicolon0103/status/1454621622652989444",
        date: "21/10/31",
        genre: "Melodious Nekochan",
        color: "red",
        title: "M3-2021秋",
        text: "個人サークル Melodious Nekochan として頒布をしました",
        divider: "",
      },
      {
        url: "https://freegame-contest.com/3-result/",
        date: "21/10/02",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "第3回新人フリーゲームコンテスト",
        text: "私が全BGMを担当した『雨にして人を外れ』が優秀賞を受賞しました",
        divider: "",
      },
      {
        url: "https://djtsar.wixsite.com/eupholicselectionsv2/download",
        date: "21/09/17",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "Eupholic Selections vol.2",
        text: "夢色の花束, エレクトリカル・トキシックが収録されました",
        divider: "",
      },
      {
        url: "https://unityroom.com/games/crazy_about_your_shot",
        date: "21/09/12",
        genre: "Micomprocedure",
        color: "light-green-lighten-1",
        title: "君の注射に超夢中",
        text: "BGMを一部担当しました",
        divider: "",
      },
      {
        url: "https://novelgame.jp/games/show/5595",
        date: "21/08/25",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "『雨にして人を外れ』",
        text: "全BGMを担当しました",
        divider: "",
      },
      {
        url: "https://freegame-contest.com/2021-2/2-result/",
        date: "21/05/31",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "第2回新人フリーゲームコンテスト",
        text: "私が全BGMを担当した『一つ屋根の下なのに』が準グランプリを受賞しました",
        divider: "",
      },
      {
        url: "http://manbow.nothing.sh/event/event.cgi?action=More_def&num=64&event=135",
        date: "21/05/31",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "MUMEI Academy 2021",
        text: "Vermitation(BMS edit.)を公開しました",
        divider: "",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol09",
        date: "21/05/29",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.09 -Lost Leisure-",
        text: "Bravelgent, Popping Nekochanが収録されました",
        divider: "",
      },
      {
        url: "https://omoidegyosen.bandcamp.com/album/--2",
        date: "21/05/02",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "オモゐデ漁船 新譜デジタルリリース",
        text: "Eternity Memoriaが収録されました",
        divider: "",
      },
      {
        url: "https://novelgame.jp/games/show/4675",
        date: "21/03/13",
        genre: "D10RAMA",
        color: "green-lighten-1",
        title: "『一つ屋根の下なのに』",
        text: "全BGMを担当しました",
        divider: "",
      },
      {
        url: "https://ksm-event.com/sfes2020/",
        date: "21/02/27",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "Shooter's Festival 2020",
        text: "Vermitation(KSM edit.)を公開しました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2020",
      },
      {
        url: "https://note.com/semicolon0103/n/na5f69a8bf619",
        date: "20/12/16",
        genre: "Article",
        color: "cyan-lighten-1",
        title: "オモゐデ漁船 Advent Calendar 2020",
        text: "16日目担当として旅行記を公開しました",
        divider: "",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol08",
        date: "20/11/22",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.08 -Evolved 2020-",
        text: "Shiftenery(KSM edit.), Skyhigh Nekochan, Daydream, Voyager Nekochan(KSM edit.)が収録されました",
        divider: "",
      },
      {
        url: "https://manbow.nothing.sh/event/event.cgi?action=More_def&num=320&event=133",
        date: "20/11/03",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "THE BMS OF FIGHTERS XVI -NEO DYSTOPIA-",
        text: "Voyager Nekochan(BMS edit.), 風の旅人の追憶を公開しました",
        divider: "",
      },
      {
        url: "https://omoidegyosen.bandcamp.com/album/what-i-love",
        date: "20/11/01",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "オモゐデ漁船 新譜デジタルリリース",
        text: "Voyager Nekochanが収録されました",
        divider: "",
      },
      {
        url: "http://manbow.nothing.sh/event/event.cgi?action=More_def&num=60&event=131",
        date: "20/06/03",
        genre: "Music Game",
        color: "light-blue-darken-1",
        title: "第17回自称無名BMS作家が物申す！",
        text: "Shiftenery(BMS edit.)を公開しました",
        divider: "",
      },
      {
        url: "http://www.proc.org.tohoku.ac.jp/?%B8%F8%B3%AB%C3%E6%A4%CE%BA%EE%C9%CA/2020%C7%AF%C5%D9SpringFestival/%A5%CF%A5%B7%A5%EC%A1%AA%A5%AE%A5%E7%A5%EB%A5%A4",
        date: "20/05/23",
        genre: "Micomprocedure",
        color: "light-green-lighten-1",
        title: "ハシレ！ギョルイ",
        text: "いくつかのBGMを担当しました",
        divider: "",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol07",
        date: "20/05/02",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.07 -7th record-",
        text: "Affectation, Cuddle Nekochanが収録されました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2019",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol06",
        date: "19/11/15",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.06 -Evolved 2019-",
        text: "Contemplation, Bayside Nekochan, Fierceness, Asteroid Nekochan, Medley Nekochanが収録されました",
        divider: "",
      },
      {
        url: "http://sakiyo.html.xdomain.jp/kyuutei/",
        date: "19/10/27",
        genre: "Micomprocedure",
        color: "light-green-lighten-1",
        title: "旧帝大コンピレーションアルバム",
        text: "Dawn, Sweetshouse, Bartimeが収録されました",
        divider: "",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol05",
        date: "19/05/02",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.05 -Overtone-",
        text: "Melancholy, Maiden Nekochanが収録されました",
        divider: "",
      },
      {
        url: "https://www.freem.ne.jp/win/game/19923",
        date: "19/04/02",
        genre: "Micomprocedure",
        color: "light-green-lighten-1",
        title: "海洋生物たちの復讐2",
        text: "いくつかのBGMを担当しました",
        divider: "",
      },
      {
        url: "https://www.freem.ne.jp/win/game/19807",
        date: "19/03/24",
        genre: "Micomprocedure",
        color: "light-green-lighten-1",
        title: "IdealMemoria",
        text: "いくつかのBGMを担当しました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2018",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo/vol04",
        date: "18/12/17",
        genre: "TonEvo",
        color: "orange-darken-3",
        title: "とんえぼパッケージVol.04 -Evolved 2018-",
        text: "Operon, Highspeed Nekochanが収録されました",
        divider: "",
      },
      {
        url: "",
        date: "",
        genre: "",
        color: "",
        title: "",
        text: "",
        divider: "2017",
      },
      {
        url: "http://www.proc.org.tohoku.ac.jp/?%BF%CA%B7%E2%A4%CE%A5%A2%A5%DB%BB%D2%A1%C1%A4%B4%C3%ED%CA%B8%A4%CF%B9%F5%CE%F2%BB%CB%A4%C7%A4%B9%A4%AB%A1%A9%A1%A9%A1%C1",
        date: "17/02/14",
        genre: "Micomprocedure",
        color: "light-green-lighten-1",
        title: "進撃のアホ子～ご注文は黒歴史ですか？？～",
        text: "BGMと一部のグラフィックを担当しました",
        divider: "",
      },
    ],
  }),
  computed: {
    items() {
      if(this.selectGenre == "All Genre" || this.selectGenre == "All Genre"){
        return this.musics
      } else {
        return this.musics.filter((music) => music.genre === this.selectGenre);
      }
    },
  },
}
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
