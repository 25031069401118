<template>
  <div class="ex-link-svg">
    <a href="https://twitter.com/semicolon0103/" target="_blank" aria-label="Twitter">
      <TwitterLogo />
    </a>
    <a href="https://semicolon0103.hatenablog.com/" target="_blank" aria-label="はてなブログ">
      <HBLogo />
    </a>
    <a href="https://semicolon0103.bandcamp.com/follow_me" target="_blank" aria-label="Bandcamp">
      <BandcampLogo />
    </a>
    <a href="https://www.youtube.com/@semicolon0103" target="_blank" aria-label="Youtube">
      <YoutubeLogo />
    </a>
    <a href="https://semicolon0103.booth.pm/" target="_blank" aria-label="Booth">
      <BoothLogo />
    </a>
  </div>
</template>

<script>
import TwitterLogo from '../assets/Twitter_logo.svg';
import HBLogo from '../assets/Hatena_blog_logo.svg';
import BoothLogo from '../assets/Booth_logo.svg';
import BandcampLogo from '../assets/Bandcamp_logo.svg';
import YoutubeLogo from '../assets/Youtube_logo.svg';

export default {
  name: 'ExLinkSVG',

  components: {
    TwitterLogo,
    HBLogo,
    BoothLogo,
    BandcampLogo,
    YoutubeLogo,
  },
}
</script>
