<template>
  <div class="top">
    <ul class="top-slides-bg-circles">
    </ul>
    <ul class="top-slides-bg-circles2">
    </ul>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-link">
          <v-row class="music-story">
            <v-col cols="12"><p class="topic">M3</p></v-col>
            <div class="my-8"></div>
            <v-col cols="12" md="6" lg="6" xl="6" xxl="6" class="m3-movie">
              <iframe src="https://www.youtube.com/embed/ARxOevjq33Q" title="【2024秋M3】Melodious Nekochan 新譜『Melodious Melodies 1』クロスフェード" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <!--<p class="m3-text">クロスフェード準備中！</p>-->
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" xxl="6" class="d-flex flex-column justify-center pb-6">
              <p class="m3-catchphrase">『Melodious Melodies 1』</p>
              <p class="m3-menu"
                > 1. すりーまいん (3:12)
                <br> 2. マスカレード (3:11)
                <br> 3. だいぼうそう (3:59)
                <br> 4. 椅子占り遊戯 (3:12)
                <br> 5. デイアイシィ (3:29)
                <br> 6. 終わりの日に (3:25)
                <br> 7. すりーまいん(inst.) (3:12)
                <br> 8. マスカレード(inst.) (3:11)
                <br> 9. だいぼうそう(inst.) (3:59)
                <br>10. 椅子占り遊戯(inst.) (3:12)
                <br>11. デイアイシィ(inst.) (3:29)
                <br>12. 終わりの日に(inst.) (3:25)
              </p>
            </v-col>
            <v-col cols="12" class="m3-circle-info d-flex flex-column mt-0">
              <!--<p class="m3-catchphrase">ポップ100%、エモさ200%。</p>-->
              <!--<p class="m3-notice">※おかげさまで完売となりました※<br></p><a href="https://semicolon0103.booth.pm/items/5692925">DL版をBOOTHにて販売開始！</a><p class="m3-notice">頒布価格: 1,500円 (同梱冊子データつき)</p>-->
              <p class="m3-text">初の書き下ろしボカロアルバムです！</p>
              <!--<p class="m3-text">G-20aでお待ちしています！！</p>-->
            </v-col>
            <v-col cols="12">
              <!--<p class="m3-text">--- お品書き準備中 ---</p>-->
              <v-img src="../assets/M3/menu.webp"/>
            </v-col>
            <!--<v-col cols="12" class="mt-12"><p class="topic">Previous M3</p></v-col>
            <a href="https://twitter.com/semicolon0103/status/1647540335491309575/" class="mt-4 mx-auto mb-16">2023春M3(告知ツイート)</a>-->
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    onReady() {
      this.$refs.youtube.playVideo()
    },
  },
};
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
